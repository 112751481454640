<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="100px" :model="form">
      <el-form-item style="margin-bottom: 20px" label="指定国家">
        <el-select
            v-model="form.country"
            multiple
            placeholder="请选择"
            size="small"
            style="width: 35%"
        >
          <el-option
              v-for="item in apiCountry"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="指定IP">
        <el-input
            type="textarea"
            size="small"
            :autosize="{ minRows: 2}"
            placeholder="请输入内容"
            v-model="form.ip"
            style="width: 35%;">
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="访问密码">
        <el-input v-model="form.password" show-password size="small" style="width: 35%" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      apiCountry: [],
      form: {
        country: '',
        shield_ip: '',
      },
    }
  },
  created() {
    this.requestCountry()
    this.requestObtain()
  },
  methods: {
    // 获取国家
    async requestCountry() {
      const { data } = await select()
      this.apiCountry = data
    },
    async requestObtain() {
      const { data } = await getExam()
      this.form.num = data.num
      this.form.content = data.content
    },
    async submit() {
      const { code, message } = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
<style>
  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
